import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { store } from '../store';
import { setRequireStatusUpdate, setUsername, setLoginStatus, setUserType, setUserED, setUserSystem } from '../actions';
import { PAGE_CLASS, TEXT_CLASS, NAV_CLASS, BACKEND_URL, FRONTEND_URL } from '../util/Constants'
import Footer from '../components/Footer';
import 'bootstrap/dist/js/bootstrap.bundle.min';
class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      endpoint: BACKEND_URL + "/api/auth",
      endpoint_login: BACKEND_URL + "/admin/login/",
      endpoint_usertype: BACKEND_URL + "/api/usertype/?username=",
      endpoint_usersED: BACKEND_URL + "/api/usersED/?username=",
      endpoint_usersSystem: BACKEND_URL + "/api/usersSystem/?username=",

    };
  }
  handleSubmit = e => {
    e.preventDefault();
    //create a header for the request
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Origin', FRONTEND_URL + '/login');

    //get access to the Login components state
    const { username, password } = this.state;
    const user = { username, password };
    store.dispatch(setUsername(username));
    const conf = {
      method: 'POST',
      body: JSON.stringify(user),
      headers: headers,
    };

    fetch(this.state.endpoint, conf).then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Invalid username or password!");
    }).then(data => {
      //update the login status in the redux store
      store.dispatch(setLoginStatus(!this.props.auth));
      this.getUserType().then(this.getUsersSystem).then(this.getUsersED).then(() => {
        window.location.href = '/';
      });
    }).catch((error) => { console.log(error); window.alert("Invalid username or password!"); })

  }
  //When a user finishes filling out the login form, update the Login components state to include the username and password
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  getUserType = () => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    //get access to the Login components state
    const { username } = this.state;
    const conf = {
      credentails: 'omit',
      mode: 'cors',
      method: 'GET',
      headers: headers,
    };
    var url = this.state.endpoint_usertype + username;
    return fetch(url, conf).then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Invalid username or password!");
    }).then(data => {
      if (data.length > 0) {
        store.dispatch(setUserType(data[0].user_type),
        )
        if (data[0].user_type === 'Hospital User' || data[0].user_type === 'ED Admin') {
          store.dispatch(setRequireStatusUpdate(true));
        }
      }
    }).catch((error) => { /*window.alert(error.message);*/ })
  }
  getUsersSystem = () => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    //get access to the Login components state
    const { username } = this.state;
    const conf = {
      credentails: 'omit',
      mode: 'cors',
      method: 'GET',
      headers: headers,
    };
    var url = this.state.endpoint_usersSystem + username;
    return fetch(url, conf).then(response => {
      if (response.ok) {
        //console.log(response)
        return response.json();
      }
      throw new Error("Invalid username or password!");
    }).then(data => {
      ;
      if (data.length > 0) {
        store.dispatch(setUserSystem(data[0].name))
      }
    }).catch((error) => {/*window.alert(error.message);*/ })
  }
  getUsersED = () => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    //get access to the Login components state
    const { username } = this.state;
    const conf = {
      credentails: 'omit',
      mode: 'cors',
      method: 'GET',
      headers: headers,
    };
    var url = this.state.endpoint_usersED + username;
    return fetch(url, conf).then(response => {
      if (response.ok) {
        //console.log(response)
        return response.json();
      }
      throw new Error("Invalid username or password!");
    }).then(data => {
      if (data.length > 0) {
        store.dispatch(setUserED(data[0].name))
      } else {
        store.dispatch(setUserED('n/a'))
      }
    }).catch((error) => {/*window.alert(error.message);*/ })
  }
  //render the component
  render() {
    const { username, password } = this.state;
    //Pass the user's authentication state to the navbar so it can display the correct options
    return (
      <div id="Login" className={PAGE_CLASS}>
        <div className={NAV_CLASS} />
        <div className={"d-flex justify-content-center align-items-center row"}>
          <div className={"col-lg-6 col-md-7 col-sm-8 col-xs-10 mx-4 my-5 box-shadow"}>
            <form className="form-signin px-5" action="/" onSubmit={this.handleSubmit}>
              <img src={require('../COTSLogo.png')} className="mt-5 rounded" height="75px" alt="logo"></img>
              <h1 className={TEXT_CLASS + "h3 mt-3 font-weight-normal"}>Welcome to Real Time Activity Status (RTAS)</h1>
              <h3 className={TEXT_CLASS + " h3 mb-3 font-weight-normal"}>Please sign in</h3>
              <label htmlFor="inputEmail" className="sr-only">Username</label>
              <input onChange={this.handleChange} type="text" value={username} name="username" id="inputEmail" className="form-control my-4" placeholder="Username" required autoFocus />
              <label htmlFor="inputPassword" className="sr-only">Password</label>
              <input onChange={this.handleChange} type="password" value={password} name="password" id="inputPassword" className="form-control mb-4" placeholder="Password" required />
              <button className="btn btn-lg cotsBlue btn-block" id="mobileclick" type="submit" placeholder="Submit"> Sign In</button>
              <p className="mt-5 mb-3 text-muted"></p>
            </form>
          </div>
        </div>

        Don't have an account? Fill out a <a href="https://www.cotshealth.org/rtas">request form</a> available on our website.

      <Footer isLogin={true} />
      </div>
    );
  }
}


export default Login;
