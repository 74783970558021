import { BACKEND_URL } from '../util/Constants';

export var siteLinks = {
  frontendLinks: {
    status: { url: "/", title: "View ED Status", id: 1 },
    statusMap: { url: "/map", title: "ED Status Map", id: 15 },
    census: { url: "/census", title: "View ED Census", id: 2 },
    callList: { url: "/notificationlist", title: "Divert Notification List", id: 3 },
    reports: { url: "/reports/", title: "Reports", id: 4 },
    contactus: { url: "/contactus/", title: "Contact Us", id: 4 },
  },
  backendLinks: {
    statusOverride: { url: BACKEND_URL + "/admin/api/status/add", title: "Update ED Status", id: 6 },
    statusHistory: { url: BACKEND_URL + "/admin/api/status/", title: "Status History", id: 7 },
    censusOverride: { url: BACKEND_URL + "/admin/api/census/add", title: "Update ED Census", id: 8 },
    censusHistory: { url: BACKEND_URL + "/admin/api/census/", title: "Census History", id: 9 },
    manageContacts: { url: BACKEND_URL + "/admin/api/contact/", title: "Manage Contacts", id: 10 },
    maintenance: {
      console: { url: BACKEND_URL + "/admin/", title: "Admin Console", id: 11 },
      users: { url: BACKEND_URL + "/admin/auth/user", title: "Users", id: 12 },
      contacts: { url: BACKEND_URL + "/admin/api/contact", title: "Contacts", id: 13 },
      messages: { url: BACKEND_URL + "/admin/api/message", title: "Messages", id: 14 },
    }
  }
}

var frontendLinks = {
  status: [siteLinks.frontendLinks.status, siteLinks.frontendLinks.statusMap],
  census: [],
  callList: [siteLinks.frontendLinks.callList],
  reports: [],
  contactus: [siteLinks.frontendLinks.contactus],
}

var frontendLinksWithReports = {
  status: [siteLinks.frontendLinks.status, siteLinks.frontendLinks.statusMap],
  census: [],
  callList: [siteLinks.frontendLinks.callList],
  reports: [siteLinks.frontendLinks.reports],
  contactus: [siteLinks.frontendLinks.contactus],
}

export var userLinks = {
  hospital_user: {
    frontend: frontendLinks,
    backend: {
      status: [siteLinks.backendLinks.statusOverride],
      census: [],
      callList: [],
      reports: [],
      maintenance: [],
    }
  },
  hospital_admin: {
    frontend: frontendLinksWithReports,
    backend: {
      status: [siteLinks.backendLinks.statusOverride,],
      census: [],
      callList: [siteLinks.backendLinks.manageContacts],
      reports: [],
      maintenance: [],
    }
  },
  cots: {
    frontend: frontendLinksWithReports,
    backend: {
      status: [siteLinks.backendLinks.statusOverride,],
      census: [],
      callList: [],
      reports: [],
      maintenance: [siteLinks.backendLinks.maintenance.console, siteLinks.backendLinks.maintenance.users, siteLinks.backendLinks.maintenance.contacts, siteLinks.backendLinks.maintenance.messages],
    }
  },
  fao: {
    frontend: frontendLinksWithReports,
    backend: {
      status: [],
      census: [],
      callList: [siteLinks.backendLinks.manageContacts,],
      reports: [],
      maintenance: [],
      eptp: [siteLinks.backendLinks.eptp,],
    }
  },
  ems: {
    frontend: frontendLinks,
    backend: [],
  }




}
