import React, { Component } from 'react';
import { PAGE_CLASS, TEXT_CLASS, HEADER_CLASS, BODY_CLASS, BACKEND_URL } from '../util/Constants'
import { Redirect } from 'react-router-dom';
import RtasNav from '../components/RtasNav';
import Footer from '../components/Footer';
import { siteLinks } from '../components/Links.js'
import { store } from '../store.js';
import { setStaleStatuses } from '../actions';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const PageWrapper = (pageName, pageID) => WrappedComponent => {
  return class Page extends Component {
    constructor(props) {
      super(props);
      this.state = {
        modalActive: false,
      }
      if (!this.props.username) {
          window.location.replace('/login');
      }
    }

    componentDidMount() {
      this.checkAuth();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.staleStatuses.length > 0) {
        if (!prevState.modalActive) {
          $('#exampleModalCenter').modal('show')
        }
        return {
          modalActive: true
        }
      } else {
        $('#exampleModalCenter').modal('hide')
        return {
          modalActive: false
        }
      }
    }

    checkAuth = () => {
      let headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');

      const conf = {
        credentails: 'omit',
        mode: 'cors',
        method: 'GET',
        headers: headers,
      };
      var url = BACKEND_URL + "/api/authcheck/"

      fetch(url, conf).then(response => {
        if (response.status === 401) {
          this.props.username = '';
          window.location.replace('/login');
        }
      }).catch((error) => { console.log('error: ', error);  })
    }

    componentWillUnmount() {
      this.setState({ modalActive: false })
    }

    //props needed to pass(auth, eptpStatus, status, pageName ,heading, childComponent)
    //this.childComponent must be wrapped in a row
    render() {
      return (
        <div id={pageID} className={PAGE_CLASS}>
          <RtasNav username={this.props.username} auth={this.props.auth} userType={this.props.userType} eptpStatus={this.props.eptpStatus} />
          <div className="username">{this.props.username}</div>
          <div className={BODY_CLASS} id={pageID + "Body"}>
            <div className="row">
              <div className={HEADER_CLASS} id={pageID + "Header"}>
                <h1 className={TEXT_CLASS}>{pageName}</h1>
              </div>
            </div>

            <div>
              <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLongTitle">Status Update Required</h5>
                      <button type="button" onClick={() => { store.dispatch(setStaleStatuses([])); this.setState({ modalActive: false }); }} className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div>
                        {this.props.staleStatuses.map((status) => { return (<div key={status.ED}><b>{status.ED}'s</b> status is older than 6 hours and needs to be updated.</div>) })}
                      </div>
                      <div>
                        Reminder: ED status should be updated every day at 0900, 1500, 2100, and 0300
                      </div>
                    </div>
                    <div className="modal-footer">
                      {/*<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>*/}
                      <a className="btn btn-secondary" href={siteLinks.backendLinks.statusOverride.url}>Update Status</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <WrappedComponent {...this.props} />
          </div>
          <Footer />
        </div>
      )
    }
  }
}


export default PageWrapper;
