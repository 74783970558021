import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { store } from '../store';
import { setLogOutStatus, setUserED } from '../actions';
import Footer from '../components/Footer';
import { PAGE_CLASS, TEXT_CLASS, NAV_CLASS, } from '../util/Constants'
import 'bootstrap/dist/js/bootstrap.bundle.min';

class Logout extends Component {

  logout() {
    store.dispatch(setUserED(''))
    store.dispatch(setLogOutStatus(!this.props.auth))
  }

  componentWillMount() {
    this.logout();
  }

  render() {
    return (
      <div id="Logout" className={PAGE_CLASS}>
        <div className={NAV_CLASS} />
        <div className={"d-flex justify-content-center align-items-center row"}>
          <div className={"col-lg-6 col-md-7 col-sm-8 col-xs-10 mx-4 my-5 box-shadow"}>
            <form className="form-signin px-5">
              <img src={require('../COTSLogo.png')} className="mt-5 rounded" height="75px" alt="logo"></img>
              <h1 className={TEXT_CLASS + "h3 mt-3 font-weight-normal"}>Thank you for using RTAS</h1>
              <h3 className={TEXT_CLASS + "h3 mt-3 font-weight-normal"}>You have been logged out</h3>
              <Link className="btn cotsBlue my-4" to='/login'>Login</Link>
            </form>
          </div>
        </div>
        <Footer isLogin={true} />
      </div>
    )
  }
}
export default Logout;
