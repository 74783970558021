import React, { Component } from 'react';
import { FOOTER_CLASS } from '../util/Constants'
import ContactUs from '../components/ContactUs'
import 'bootstrap/dist/js/bootstrap.bundle.min';
export default class Footer extends Component {

  render() {
    if (this.props.isLogin) {
      return (
        <div id="Footer" className={"py-3 " + FOOTER_CLASS}>

          <img src={require('../COTS_LOGO.png')} height="50px" alt="" />
          <a href="https://www.cotshealth.org"><h6>COTS</h6></a>
        </div>
      )
    }
    return (
      <div id="Footer" className={"py-3 " + FOOTER_CLASS}>

        <img src={require('../COTS_LOGO.png')} height="50px" alt="" />
        <a href="https://www.cotshealth.org"><h6>COTS</h6></a>
        <ContactUs />
      </div>
    );
  }
}
