import React, { Component } from 'react';
import { store, persistor } from '../store.js';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import App from '../App'
import Spinner from './spinner'
//Main routing components
//
class Routing extends Component {
  render() {
    return (
      <PersistGate loading={<Spinner />} persistor={persistor}>
        <Provider store={store}>
          <App />
        </Provider>
      </PersistGate>
    )
  }
}

export default Routing;
