//This file contains site constants
export const FRONTEND_URL = "https://www.cotsrtas.org";
export const BACKEND_URL = "https://www.cotsrtas.org/management";
export const GOOGLE_MAPS_API_KEY = 'AIzaSyBY8ZqJo07q9LPAynTuJCVxMck3xz8asJM';

export const TABLE_CLASS = "table table-bordered shadow mt-0"
export const HEADER_CLASS = "col bg-light shadow-sm mb-0"
export const SUBHEADER_CLASS = "container-fluid shadow-sm cotsBlue"
export const NAV_CLASS = "navbar navbar-expand-lg navbar-dark cotsBlue sticky-top shadow "
export const EPTP_CLASS = " eptp-light "
export const BODY_CLASS = "container border mt-3 mb-3 rounded"
export const PAGE_CLASS = ""
export const TEXT_CLASS = " py-1"
export const BORDER_CLASS = "border rounded border-secondary "
export const BUTTON_CLASS = "btn btn-sm btn-light btn-block "
export const FOOTER_CLASS = "cotsBlue shadow-top footer "
