import React, { Component } from 'react';
import { TABLE_CLASS, BACKEND_URL, FRONTEND_URL } from '../util/Constants'
import { store } from '../store';
import { setCensus } from '../actions/index.js';
import FormatedDateTime from './FormatDateTime.js'
import PageWrapper from '../wrappers/Page';

import 'bootstrap/dist/js/bootstrap.bundle.min';
class Census extends Component {

  constructor(props) {
    super(props);
    this.state = {
      census: [], //initialize to empty array
      auth: this.props.auth, //receive the auth census from the AppContainer
      endpoint: BACKEND_URL + "/api/census/", //url for accessing the backend census api
      updated: ""
    }
  }
  /*------------------DATA FETCHING METHODS------------------------------*/
  updateTime = () => {
    var objToday = new Date(),
      curYear = objToday.getFullYear(),
      curMonth = objToday.getMonth() + 1 < 10 ? "0" + (objToday.getMonth() + 1) : objToday.getMonth() + 1,
      curDay = objToday.getDate() < 10 ? "0" + objToday.getDate() : objToday.getDate(),
      curHour = objToday.getHours() < 10 ? "0" + objToday.getHours() : objToday.getHours(),
      curMinute = objToday.getMinutes() < 10 ? "0" + objToday.getMinutes() : objToday.getMinutes();
    var today = curMonth + "/" + curDay + "/" + curYear + " " + curHour + ":" + curMinute;
    //console.log(today)
    this.setState({ updated: today })
  }

  handleMount = () => {
    //create the header and append attributes to the header
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Origin', FRONTEND_URL + '/census');
    //set the fetch configurations
    const conf = {
      method: 'get',
      headers: headers,
    };
    //fetch the resource from the backend and set the census to the response data
    return fetch(this.state.endpoint, conf).then(response => {
      if (response.ok) {
        return response.json()
      }
      throw new Error("census table load failure");
    }).then(jsonData => {
      store.dispatch(setCensus(jsonData))
      this.updateTime();
    }).catch((error) => { })

  }
  /*------------------LIFE CYCLE METHODS------------------------------*/

  /*Called before the component is loaded. If the redux store updates, then new props will be passed to this component
  and it will re-render*/
  componentWillMount() {
    this.handleMount();
    //rerender the component every 15 minutes (15 * 60 * 1000 = 900000)
    this.interval = setInterval(() => this.handleMount(), 900000);

  }
  //After the component unmounts, this is called to clear the time interval
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  /*If the user is not authenticated, render a redirect to the login page, otherwise display the census table.
  */
  render() {
    return (
      <div>
        <div className="row">
          <div className="container-fluid mt-2">{"Last Updated at: " + this.state.updated} </div>
          <div className="col mt-4">
            <EDCensusTable table_id="CensusTableHospital" ed_type="Hospital ED"
              census={this.props.census.filter((location) => location.ED.facility === 1)} />
          </div>
          <div className="col mt-4">
            <EDCensusTable table_id="CensusTableFreeStanding" ed_type="Free Standing ED"
              census={this.props.census.filter((location) => location.ED.facility === 2)} />
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-3"></div>
          <div className="col-lg">
            <EDCensusTable table_id="CensusTableOutOfCounty" ed_type="Out of County ED"
              census={this.props.census.filter((location) => location.ED.facility === 3)} />
          </div>

          <div className="col-3"></div>
        </div>
      </div>

    );
  }
}
/*Component representing the Census Table and header. Each row in the table is
a subcomponent, CensusTableEntry.
}*/
export class EDCensusTable extends Component {
  render() {
    return (
      <table id={this.props.table_id} className={TABLE_CLASS}>
        <thead className="cotsBlue">
          <tr className="cotsBlue">
            <th style={{ width: '45%' }} scope="col">{this.props.ed_type}</th>
            <th style={{ width: '20%' }} scope="col">Census</th>
            <th style={{ width: '35%' }} scope="col">Updated</th>
          </tr>
        </thead>
        <tbody>
          {this.props.census.map((location) => { return (<CensusTableEntry key={location.ED.name} location={location} />) })}
        </tbody>
      </table>
    )
  }
}
/*Represents a row in the Census Table.*/
export class CensusTableEntry extends Component {
  isStale = (updated, ed) => {
    // format of djano time "2019-03-27T19:08:53.794642-04:00"
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    var updatedFormat = months[parseInt(updated.substring(5, 7)) - 1] + " " + updated.substring(8, 10) + ", " + updated.substring(0, 4) + " " + updated.substring(11, 19) + " UTC" + updated.substring(26, 32)
    //console.log(updatedFormat)
    var date1 = new Date(updatedFormat);
    //console.log(date1)
    var today = new Date();
    var hours = Math.abs(today - date1) / 36e5;
    return hours > 8;
  }
  render() {
    return (
      <tr key={this.props.location.ED.name}>
        <td className="hname">
          <span>{this.props.location.ED.name}</span>
        </td>
        <td>
          <span >{this.props.location.data}</span>
        </td>
        <td className={this.isStale(this.props.location.updateDateTime) ? "StaleStatus" : ""}>
          <FormatedDateTime visible={true} dt_str={this.props.location.updateDateTime} />
        </td>
      </tr>
    );
  }
}


//page wrapper renders the skeleton for each page,
//the first set of parameters are (heading_value, component_id)
//the second set of parameters is just (Component) where Component is the component you wish to render in the page skelton
export default PageWrapper('ED Census', 'Census')(Census);
