import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

import 'bootstrap/dist/js/bootstrap.bundle.min';
class Comment extends Component {


  render() {
    if (this.props.visible && this.props.comment) {
      return (
        <ReactTooltip globalEventOff='click' id={'Comment' + this.props.location.ED.name} type='light' effect='float'>
          <div className="mb-1"><b>Comment: </b>{this.props.comment}</div>
          {this.props.facilityAlert.length ? <div className="mb-1">This ED has active facility alerts</div> : <span/>}
          {/*
                <div><b>Diversion Reasons:</b> {this.props.reasons ? this.props.reasons.map((reason) => { return (<div key={reason.id} className="my-1">{reason.description} </div>) }) : ""}</div>
          */}
        </ReactTooltip>
      )
    }
    return (
      <div></div>
    )
  }
}

export default Comment;
