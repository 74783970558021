import React, { Component } from 'react';
import PageWrapper from '../wrappers/Page';
class PageNotFound extends Component {

  render() {
    return (
      <div id="PageNotFound" className="mt-5">
        <p className="mt-5">It looks like this page doesn't exist, try one of the links in the navigation bar </p>
      </div>
    );
  }
}


export default PageWrapper('OOPS! Page Not Found', 'PageNotFound')(PageNotFound);
