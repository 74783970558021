
import { createStore } from 'redux';
//import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from './reducers';
import { devToolsEnhancer } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native

const persistConfig = {
  key: 'root',
  blacklist: ['eptpConfirmationVisibility', 'staleStatuses'],
  storage,
}
const persistedReducer = persistReducer(persistConfig,rootReducer)


export const store = createStore(persistedReducer, /* preloadedState, */ devToolsEnhancer(
    // Specify custom devTools options
  ));
  
export const persistor = persistStore(store)
