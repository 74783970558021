import { combineReducers } from 'redux';
import * as actionType from '../actions/types';
const requireStatusUpdate = (state = false, action) => {
  switch (action.type) {
    case actionType.SET_REQUIRE_STATUS_UPDATE:
      return action.data
    case actionType.LOG_OUT:
      return false
    default:
      return state
  }
}
const messages = (state = [], action) => {
  switch (action.type) {
    case actionType.SET_MESSAGES:
      return action.message
    case actionType.LOG_OUT:
      return []
    default:
      return state
  }
}
const eptpConfirmationVisibility = (state = false, action) => {
  switch (action.type) {
    case actionType.SET_CONFIRMATION_VISIBILITY:
      return action.visible
    case actionType.LOG_OUT:
      return false
    default:
      return state
  }
}
const dailyLog = (state = [], action) => {
  switch (action.type) {
    case actionType.SET_LOG:
      return action.data
    case actionType.LOG_OUT:
      return []
    default:
      return state
  }
}
const staleStatuses = (state = [], action) => {
  switch (action.type) {
    case actionType.SET_STALE_STATUSES:
      return action.statuses
    case actionType.LOG_OUT:
      return []
    default:
      return state
  }
}
const loginStatus = (state = false, action) => {
  switch (action.type) {
    case actionType.LOG_IN:
      return action.status
    case actionType.LOG_OUT:
      return false
    default:
      return state;
  }
}
const statusList = (state = [], action) => {
  switch (action.type) {
    case actionType.SET_STATUS:
      return action.data
    case actionType.LOG_OUT:
      return []
    default:
      return state
  }
}
const censusList = (state = [], action) => {
  switch (action.type) {
    case actionType.SET_CENSUS:
      return action.data
    case actionType.LOG_OUT:
      return []
    default:
      return state
  }
}

const contactList = (state = [], action) => {
  switch (action.type) {
    case actionType.SET_CONTACT_LIST:
      return action.data
    case actionType.LOG_OUT:
      return []
    default:
      return state
  }
}

const EPTP_Is_Active = (state = false, action) => {
  switch (action.type) {
    case actionType.TOGGLE_EPTP:
      return action.status
    case actionType.LOG_OUT:
      return false
    default:
      return state
  }
}
const EPTPStatus = (state = [], action) => {
  switch (action.type) {
    case actionType.SET_EPTP_STATUS:
      return action.status
    case actionType.LOG_OUT:
      return []
    default:
      return state
  }
}
const userType = (state = "", action) => {
  switch (action.type) {
    case actionType.SET_USER_TYPE:
      return action.utype
    case actionType.LOG_OUT:
      return ""
    default:
      return state
  }
}
const eds = (state = "", action) => {
  switch (action.type) {
    case actionType.SET_EDS:
      return action.eds
    case actionType.LOG_OUT:
      return ""
    default:
      return state
  }
}
const userED = (state = "", action) => {
  switch (action.type) {
    case actionType.SET_USER_ED:
      return action.uED
    case actionType.LOG_OUT:
      return ""
    default:
      return state
  }
}
const username = (state = "", action) => {
  switch (action.type) {
    case actionType.SET_USERNAME:
      return action.username
    case actionType.LOG_OUT:
      return ""
    default:
      return state
  }
}
const userSystem = (state = "", action) => {
  switch (action.type) {
    case actionType.SET_USER_SYSTEM:
      return action.uSystem
    case actionType.LOG_OUT:
      return ""
    default:
      return state
  }
}
const appReducer = combineReducers({
  loginStatus, statusList, EPTP_Is_Active, dailyLog, censusList, contactList, EPTPStatus, userType, userED, userSystem, username, eptpConfirmationVisibility, requireStatusUpdate, staleStatuses, messages, eds
})

const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT') {
    state = undefined
  }
  return appReducer(state, action);
}

export default rootReducer;
