export const SET_LOG = "SET_LOG";
export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const REDIRECT_URL = "REDIRECT_URL";
export const SET_STATUS = "SET_STATUS";
export const CLEAR_STATUS = "CLEAR_STATUS";
export const TOGGLE_EPTP = "TOGGLE_EPTP";
export const SET_CENSUS = "SET_CENSUS";
export const SET_CONTACT_LIST = "SET_CONTACT_LIST"
export const SET_EPTP_STATUS = "SET_EPTP_STATUS";
export const SET_USER_TYPE = "SET_USER_TYPE";
export const SET_EDS = "SET_EDS";
export const SET_USER_ED = "SET_USER_ED";
export const SET_USER_SYSTEM = "SET_USER_SYSTEM";
export const SET_USERNAME = "SET_USERNAME";
export const SET_CONFIRMATION_VISIBILITY = "SET_CONFIRMATION_VISIBILITY";
export const SET_REQUIRE_STATUS_UPDATE = "SET_REQUIRE_STATUS_UPDATE";
export const SET_STALE_STATUSES = "SET_STALE_STATUSES";
export const SET_MESSAGES = "SET_MESSAGES";
