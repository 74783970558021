/*
*/
import React, { Component } from 'react';

import 'bootstrap/dist/js/bootstrap.bundle.min';
// example of unformatted date time before formating completed 2019-02-25T14:51:00.228883-05:00
//YYYY-MM-DDTHH:MM.SSSSSS-05:00
//needs reformating to MM/DD/YY HH:MM AM/PM
class FormatedDateTime extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  month = (dt_str) => {
    return dt_str.substring(5, 7);
  }
  day = (dt_str) => {
    return dt_str.substring(8, 10);
  }
  year = (dt_str) => {
    return dt_str.substring(0, 4);
  }
  hour = (dt_str) => {
    return dt_str.substring(11, 13);
  }
  minute = (dt_str) => {
    return dt_str.substring(13, 16);
  }

  formatTime = (dt_str) => {
    return (this.hour(dt_str) + this.minute(dt_str));
  }

  formatDateTime = (dt_str) => {
    return (
      this.month(dt_str) + "/" +
      this.day(dt_str) + "/" +
      this.year(dt_str) + " " +
      this.formatTime(dt_str));
  }
  render() {
    if (this.props.visible) { return (<span>{this.props.optionalPrefix}{this.formatDateTime(this.props.dt_str)} </span>); }
    return (<span />)
  }
}

export default FormatedDateTime;
