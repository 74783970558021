import PageWrapper from '../wrappers/Page';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

import 'bootstrap/dist/js/bootstrap.bundle.min';
class ContactUsPage extends Component {


  render() {
    return (
      <span>
          <div className="mb-1"> <b><u>RTAS platform/software issues ONLY</u></b></div>
          <div className="mb-1"> <b>FOR HOURS 08:00- 17:00 Monday thru Friday</b></div>
          <div className="mb-1"> Contact: Roxanna Giambri</div>
          <div className="mb-1"> Email: <a href="mailto:rgiambri@cotshealth.org?Subject=RTAS%20Contact%20Us%20Message">rgiambri@cotshealth.org</a></div>
          <div className="mb-1"> Phone:<a href="tel: +6142554406">(614)255-4406</a></div>
          <div className="mb-1"></div>

          <div className="mb-1"> <b><u>After hours for EMERGENCY software isssues or disaster situational awareness:</u></b></div>
          <div className="mb-1"> Contact: COTS Healthcare Incident Liaison (HIL)</div>
          <div className="mb-1"> Email: <a href="mailto:cotshil1@onpage.com?Subject=RTAS%20Contact%20Us%20Message">cotshil1@onpage.com</a> [include your contact info]</div>
          <div className="mb-1"> Phone: <a href="tel: +18552667243">1(855)266-7243</a>, enter ID: 26874451, and enter your call back number </div>
          <div className="mb-1"></div>

          <div className="mb-1"> <b><u>Emergency Patient Transport Plan "Distribution" issues:</u></b></div>
          <div className="mb-1"> Contact the Columbus Fire Department Alarm Office (FAO) <a href="tel: +16142212345">(614)-221-2345</a></div>
      </span>)
  }
}

export default PageWrapper('Contact Us', 'ContactUsPage')(ContactUsPage);
