import React, { Component } from 'react'
import { statusCodes, codeStylesDark } from '../util/Enums';
import { store } from '../store';
import { setStatus } from '../actions/index.js';
import { FRONTEND_URL, BACKEND_URL } from '../util/Constants';
import ReactTooltip from 'react-tooltip';
import FormatedDateTime from './FormatDateTime.js'
import PageWrapper from '../wrappers/Page';
import { Redirect } from 'react-router-dom';

const MINIMUM_WIDTH_THRESHOLD = 380;
const MOBILE_WIDTH_THRESHOLD = 550;

let id_to_purpose = [
    'unused',
    'Preface (Added FA)',
    'ED Name',
    'unused',
    'Midsection',
    'Facility Alert Label',
    'Epilogue',
    'Preface (Removed FA)',
]
let purpose_to_id = {
    PrefaceAdd: 1,
    EdName: 2,
    Midsection: 4,
    Epilogue: 6,
    FaName: 5,
    PrefaceRem: 7,
}

class FacilityAlertSnippets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endpoint: BACKEND_URL + '/api/facilityAlertSnippets/', //url for accessing the backend status api
      error: '',
      isLoading: true,
      generalSnippets: [],
      edSnippets: [],
      facilityAlertSnippets: [],

      // The "selected" snippets
      general: null,
      ed: null,
      fa: null,
    }
  }

  componentWillMount() {
    this.fetchSnippetData();
  }

  componentWillUnmount() {}

  fetchSnippetData() {
    //create the header and append attributes to the header
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Origin', FRONTEND_URL + '/status');
    //set the fetch configurations
    const conf = {
      method: 'get',
      headers: headers,
    };
    //fetch the resource from the backend and set the status to the response data
    return fetch(this.state.endpoint, conf).then(response => {
      if (response.ok) {
        return response.json()
      }
      throw new Error("HTTP Response not OK");
    }).then(
        (jsonData) => this.sortSnippets(jsonData)
    ).catch(
        (error) => {
            this.setState({ error: "Failed to load snippet data." });
            console.log('Caught error: ', error);
        }
    ).finally(
        () => this.setState({ isLoading: false })
    );
  }

  sortSnippets(snippetList) {
    let general = [];
    let ed = [];
    let facilityAlert = [];
    snippetList.forEach(snippet => {
        let dest = [];
        let pto = purpose_to_id;
        if ([pto.PrefaceAdd, pto.PrefaceRem].includes(snippet.purpose)) {
            dest = general;
        } else if (snippet.purpose == pto.EdName) {
            dest = ed;
        } else if (snippet.purpose == pto.FaName) {
            dest = facilityAlert;
        }
        dest.push(snippet);
    });
    this.setState(prevSt => ({
        generalSnippets: prevSt.generalSnippets.concat(general),
        edSnippets: prevSt.edSnippets.concat(ed),
        facilityAlertSnippets: prevSt.facilityAlertSnippets.concat(facilityAlert),
    }))
    console.log('well its sorted: ', this.state);
  }
 
  render() {
    let containerStyle = {
        minWidth: 300 +'px',
        minHeight: 500 +'px',
        display: 'flex',
        flexDirection: 'column',
    };

    let suppliedEdIds = this.state.edSnippets.map(snip => snip.ED.id);
    let missingEds = this.props.eds.filter(function (ed) {
        return !suppliedEdIds.includes(ed.id);
    }.bind(this)).map(ed => ed.name);

    let missingFas = [];

    return (
      <div style={containerStyle}>
        {this.state.isLoading && "Loading..."}
        {this.state.error && this.state.error}
        {!this.state.error && !this.state.isLoading && (<>
            <div className="mt-3" style={{ height: '100%', width: '100%', flexGrow: 1 }}>
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th>General Snippets</th>
                            <th>ED Snippets</th>
                            <th>Facility Alert Snippets</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {this.state.generalSnippets.map(snippet => <SnippetBlurb
                                    snippet={snippet}
                                    type="general"
                                    onClick={() => this.setState({ general: snippet })}
                                    active={snippet === this.state.general}
                                />)}
                            </td>
                            <td>
                                {this.state.edSnippets.map(snippet => <SnippetBlurb
                                    snippet={snippet}
                                    type="ed"
                                    onClick={() => this.setState({ ed: snippet })}
                                    active={snippet === this.state.ed}
                                />)}
                            </td>
                            <td>
                                {this.state.facilityAlertSnippets.map(snippet => <SnippetBlurb
                                    snippet={snippet}
                                    type="fa"
                                    onClick={() => this.setState({ fa: snippet })}
                                    active={snippet === this.state.fa}
                                />)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <RecordingGenerator general={this.state.general} ed={this.state.ed} fa={this.state.fa}/>
            <div>Missing ED snippets: { missingEds.join(', ') }</div>
        </>)}
      </div>
    );
  }
}

function getLabelForSnippet(snippet, type) {
    if (type == 'ed') {
        return snippet.ED.name;
    } else if (type == 'fa') {
        return snippet.facilityAlert.description;
    }
    return id_to_purpose[snippet.purpose];
}

class SnippetBlurb extends Component {
  render() {
    let snippet = this.props.snippet;
    let label = getLabelForSnippet(snippet, this.props.type);
    return (
        <div 
            onClick={this.props.onClick}
            className={"rounded border p-3 m-3 mt-0" + (this.props.active ? " bg-primary text-white" : '')}
        >{label}</div>
    );
  }
}

class RecordingGenerator extends Component {
    render() {
        return (
            <div className="p-3" style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div>{this.props.general ? getLabelForSnippet(this.props.general, 'general') : '{ general not set }'}</div>
                <div>{this.props.ed ? getLabelForSnippet(this.props.ed, 'ed') : '{ ed not set }'}</div>
                <div>{this.props.fa ? getLabelForSnippet(this.props.fa, 'fa') : '{ fa not set }'}</div>
                <button onClick={() => this.generateRecording()}>Generate Full Recording</button>
            </div>
        );
    }

    generateRecording() {
        if (!this.props.general || !this.props.ed || !this.props.fa) {
            alert("Please select a snippet of each type");
            return;
        }

        console.log('props: ', this.props);
        let endpoint = BACKEND_URL + 
            '/api/generateFacilityAlertWav'+
            '?general=' + (this.props.general.purpose > 1 ? 0 : 1) +
            '&ed=' +this.props.ed.ED.id +
            '&fa=' +this.props.fa.facilityAlert.id;
        //create the header and append attributes to the header
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin', FRONTEND_URL + '/status');
        //set the fetch configurations
        const conf = {
          headers: headers,
        };
        //fetch the resource from the backend and set the status to the response data
        return fetch(endpoint, conf).then(response => {
            if (response.ok) {
                return response.blob()
            }
            throw new Error("HTTP Response not OK");
        }).then((blob) => {
            console.log('Got response... ', blob)
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'output.wav';
            document.body.append(a);
            a.click();
            a.remove();
        }).catch((error) => {
            this.setState({ error: "Failed to generate wav." });
            console.log('Caught error: ', error);
        }).finally(
            () => this.setState({ isLoading: false })
        );
    }
}


export default PageWrapper('Facility Alert Snippets', 'FacilityAlertSnippets')(FacilityAlertSnippets);
