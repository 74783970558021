import * as actionType from './types';

export const setMessages = (message) => {
  return {
    type: actionType.SET_MESSAGES,
    message
  }
}
export const setRequireStatusUpdate = (data) => {
  return {
    type: actionType.SET_REQUIRE_STATUS_UPDATE,
    data
  }
}
export const setStaleStatuses = (statuses) => {
  return {
    type: actionType.SET_STALE_STATUSES,
    statuses
  }
}
export const setDailyLog = (data) => {
  return {
    type: actionType.SET_LOG,
    data
  }
}
export const setConfirmationVisibility = (visible) => {
  return {
    type: actionType.SET_CONFIRMATION_VISIBILITY,
    visible
  }
}
export const setUsername = (username) => {
  return {
    type: actionType.SET_USERNAME,
    username
  }
}
export const setStatus = (data) => {
  return {
    type: actionType.SET_STATUS,
    data
  }
}
export const setCensus = (data) => {
  return {
    type: actionType.SET_CENSUS,
    data
  }
}
export const setContactList = (data) => {
  return {
    type: actionType.SET_CONTACT_LIST,
    data
  }
}
export const toggleEPTP = (status) => {
  return {
    type: actionType.TOGGLE_EPTP,
    status
  }
}
export const setUserType = (utype) => {
  return {
    type: actionType.SET_USER_TYPE,
    utype
  }
}
export const setEDs = (eds) => {
  return {
    type: actionType.SET_EDS,
    eds
  }
}
export const setUserED = (uED) => {
  return {
    type: actionType.SET_USER_ED,
    uED
  }
}
export const setUserSystem = (uSystem) => {
  return {
    type: actionType.SET_USER_SYSTEM,
    uSystem
  }
}
export const setEPTPStatus = (status) => {
  return {
    type: actionType.SET_EPTP_STATUS,
    status
  }
}
export const setLoginStatus = (status) => {
  return {
    type: actionType.LOG_IN,
    //boolean value
    status
  }
}
export const setLogOutStatus = (status) => {
  return {
    type: actionType.LOG_OUT,
    //boolean value
    status
  }
}
