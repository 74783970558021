import React, { Component } from 'react';
import PageWrapper from '../wrappers/Page';
import { TABLE_CLASS, BACKEND_URL, FRONTEND_URL } from '../util/Constants'
import { store } from '../store';
import { setContactList, setUserED, setUserSystem } from '../actions/index.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';

class CallList extends Component {
  constructor(props) {
    super(props);

    var endpoint = BACKEND_URL + '/api/contacts';

    // Set initial filters based on user type and ED/HS
    let filteredEdIds = [];

    if (this.props.userED && this.props.userED != 'n/a' && this.props.eds) {
        filteredEdIds = this.props.eds.filter(ed => ed.name == this.props.userED).map(ed => ed.id) 
    } else if (this.props.userSystem && this.props.eds) {
        filteredEdIds = this.props.eds.filter(ed => ed.system.name == this.props.userSystem).map(ed => ed.id)
    }

    this.state = {
      contacts: [], //initialize to empty array
      auth: this.props.auth, //receive the auth census from the AppContainer
      endpoint: endpoint,

      endpoint_usersED: BACKEND_URL + "/api/usersED/?username=",
      endpoint_usersSystem: BACKEND_URL + "/api/usersSystem/?username=",

      filteredEdIds: filteredEdIds,
    };
  }

  /*------------------DATA FETCHING METHODS------------------------------*/
  loadContacts = () => {
    //create the header and append attributes to the header
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Origin', FRONTEND_URL + '/call-list');
    //set the fetch configurations
    const conf = {
      method: 'get',
      headers: headers,
    };
    this.setState({
        loading: true,
    });
    //fetch the resource from the backend and set the contacts to the response data
    return fetch(this.state.endpoint + '?eds=' + encodeURI(JSON.stringify(this.state.filteredEdIds)), conf).then(response => {
      if (response.ok) {
        return response.json()
      }
      throw new Error("contacts table load failure");
    }).then(jsonData => {
        this.setState({
            contacts: jsonData,
            loading: false,
        })
    }).catch((error) => { 
        this.setState({
            loading: true,
        });
    })

  }

  onChangeFilteredEds = (filteredEdIds) => {
    this.setState({
        filteredEdIds: filteredEdIds
    }, this.loadContacts.bind(this));
  }

  /*------------------LIFE CYCLE METHODS------------------------------*/

  /*Called before the component is loaded. If the redux store updates, then new props will be passed to this component
  and it will re-render*/
  componentWillMount() {
    this.loadContacts();
    //rerender the component every 15 minutes (15 * 60 * 1000 = 900000)
    this.interval = setInterval(() => this.handleMount(), 900000);

  }
  //After the component unmounts, this is called to clear the time interval
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div id="NotificationList">
        <div className="row mt-2">
          <div className="col-lg">
            <CallListTable table_id={"NotificationList"} user_type={this.props.userType}
              contacts={this.state.contacts} eds={this.props.eds} onChangeFilteredEds={(val) => this.onChangeFilteredEds(val)} filteredEdIds={this.state.filteredEdIds} loading={this.state.loading}/>
          </div>
        </div>
      </div>
    );
  }
}


/*Component representing the Call List Table and header. Each row in the table is
a subcomponent, CallListTableEntry.
}*/
export class CallListTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
        nameSort: 1,
        numberSort: 0,
    };
  }

  setNameSort() {
    let newSort = this.state.nameSort == 1 ? -1 : 1;
    this.setState({
        nameSort: newSort,
        numberSort: 0,
    });
  }

  setNumberSort() {
    let newSort = this.state.numberSort == 1 ? -1 : 1;
    this.setState({
        nameSort: 0,
        numberSort: newSort,
    });
  }

  displayAlphaSort(sortVal) {
    switch(sortVal) {
        case 1: return 'A-Z';
        case -1: return 'Z-A';
        default: return 'none';
    }
  }
  displayNumericSort(sortVal) {
    switch(sortVal) {
        case 1: return 'lo-hi';
        case -1: return 'hi-lo';
        default: return 'none';
    }
  }

  getSortedContacts() {
    const contacts = [...this.props.contacts];
    if (this.state.nameSort != 0) {
        contacts.sort((a,b) => this.state.nameSort * a.name.localeCompare(b.name));
    }
    else if (this.state.numberSort != 0) {
        contacts.sort((a,b) => this.state.numberSort * a.phoneNumber.localeCompare(b.phoneNumber));
    }
    return contacts;
  }

  render() {
    return (
    <>
      <CallListFilter eds={this.props.eds} onChangeFilteredEds={(val) => this.props.onChangeFilteredEds(val)} filteredEdIds={this.props.filteredEdIds} />
      <div class="loading-panel">{this.props.loading && <>Loading contacts...</>}</div>
      <table id={this.props.table_id} className={TABLE_CLASS}>
        <thead className="cotsBlue">
          <tr className="cotsBlue">
            <th style={{width: '20%'}} scope="col">
                Name<br/>
                <button class="sort-button" onClick={() => this.setNameSort()}>Sort: {this.displayAlphaSort(this.state.nameSort)}</button>
            </th>
            <th style={{width: '20%'}} scope="col">
                Phone Number<br/>
                <button class="sort-button" onClick={() => this.setNumberSort()}>Sort: {this.displayNumericSort(this.state.numberSort)}</button>
            </th>
            <th scope="col">Receive 3 EDs On Divert Notification</th>
            <th scope="col">Receive EPTP Activated/Deactived Notification</th>
            <th scope="col">Status Threshold for Any ED</th>
            <th scope="col">Status Threshold for Listed EDs</th>
            <th style={{width: '25%'}} scope="col">Emergency Depts.</th>
          </tr>
        </thead>
        <tbody>
          {this.getSortedContacts().map((contact) => {
            return (<CallListTableEntry key={contact.id} name={contact.name} eds={contact.eds}
              edSystem={contact.edSystem}
              anyEdMinimumStatus={contact.anyEdMinimumStatus}
              specificEdsMinimumStatus={contact.specificEdsMinimumStatus}
              receiveEPTP={contact.receiveEPTP}
              receive3Div={contact.receive3Div}
              number={contact.phoneNumber} />)
          })}
        </tbody>
      </table>
    </>
    )
  }
}

/*Represents a row in the Call List Table.*/
export class CallListTableEntry extends Component {
  formatPhoneNumber(number) {
    return "(" + number.substring(0, 3) + ") " + number.substring(3, 6) + "-" + number.substring(6, number.length);
  }

  formatStatusThreshold(statusThreshold) {
    switch (statusThreshold) {
        case 5: return 'Divert or Higher';
        case 6: return 'Divert Equipment Failure or Higher';
        case 7: return 'Closed';
        default: return 'Not Set';
    }
  }

  render() {
    const sortedEdNames = this.props.eds.map(ed => ed.name);
    sortedEdNames.sort();
    return (
      <tr>
        <td className="name">
          <span>{this.props.name}</span>
        </td>
        <td>
          <span>{this.formatPhoneNumber(this.props.number)}</span>
        </td>
        <td>{this.props.receive3Div ? 'Yes' : 'No'}</td>
        <td>{this.props.receiveEPTP ? 'Yes' : 'No'}</td>
        <td>
            {this.formatStatusThreshold(this.props.anyEdMinimumStatus)}
        </td>
        <td>
            {this.formatStatusThreshold(this.props.specificEdsMinimumStatus)}
        </td>
        <td style={{ textAlign: 'left' }}>
          <ul style={{ margin: '0px' }}>
            {sortedEdNames.map(name => (<li>{name}</li>))}
            {!!this.props.edSystem && <li>Hospital System: {this.props.edSystem.name}</li> }
          </ul>
        </td>
      </tr>
    );
  }
}

class CallListFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            'isCollapsed': true
        }
    }

    onChangeFilter(id) {
        let ids = [];
        if (this.props.filteredEdIds.includes(id)) {
            ids = this.props.filteredEdIds.filter(fId => fId != id);
        } else {
            ids = [...this.props.filteredEdIds, id];
        }
        this.props.onChangeFilteredEds(ids);
    }

    getEdNamesForIds() {
        const eds = this.props.eds.filter(ed => this.props.filteredEdIds.includes(ed.id));

        if (eds.length) {
            eds.sort(ed => (a,b) => a.name.localeCompare(b.name))
            return <>Listing contacts who are specifically subscribed to the diversion notifications for any of the following EDs:<br/>{eds.map(ed => ed.name).join(', ')}</>
        } else {
            return 'No filter applied. Listing contacts that do not specificy specific EDs to be notified about. (e.g. FAO/COTS/EMS users)';
        }
    }

    onToggleCollapse() {
        this.setState({
            'isCollapsed': !this.state.isCollapsed,
        })
    }

    render() {
      let cols = [[],[],[]];
      let edsPerCol = 0;
      this.props.eds.sort((a,b) => a.name.localeCompare(b.name))
      if (this.props.eds) {
          const numEds = this.props.eds.length;
          edsPerCol = Math.ceil(numEds / 3);
          cols[0] = this.props.eds.slice(0, edsPerCol);
          cols[1] = this.props.eds.slice(edsPerCol, 2*edsPerCol);
          cols[2] = this.props.eds.slice(2*edsPerCol);
      }

      return (
        <table className={`contact-list-filter-panel ${TABLE_CLASS}`}>
            <thead className="cotsBlue"><tr><th colspan="2">
                Filter by ED
                <button class="sort-button" onClick={() => this.onToggleCollapse()}>{this.state.isCollapsed ? 'Expand' : 'Collapse'}</button>
                <button class="sort-button" onClick={() => this.props.onChangeFilteredEds([])}>Clear</button>
            </th></tr></thead>
            <tbody style={{ textAlign: 'left' }} className={ this.state.isCollapsed ? 'collapsed':'expanded' }>
                <table class="w100">
                    <tbody>
                        {[...Array(edsPerCol).keys()].map(i => <tr>
                            {[0,1,2].map(j => 
                            i < cols[j].length && <td>
                                <input id={'filter-checkbox-'+i+'-'+j} className="filter-checkbox" type="checkbox" checked={this.props.filteredEdIds.includes(cols[j][i].id)} onChange={() => this.onChangeFilter(cols[j][i].id)}/>
                                <label for={'filter-checkbox-'+i+'-'+j}>{cols[j][i].name}</label>
                            </td>)}
                        </tr>)}
                    </tbody>
                </table>
            </tbody>
            <tfoot>
                <tr className="cotsBlue text-left p-0">
                    <td colspan="3">
                        {this.getEdNamesForIds()}
                    </td>
                </tr>
            </tfoot>
        </table>
      )
    }
}

export default PageWrapper('Divert Notification List', "CallList")(CallList);
